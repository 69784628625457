import Logo from './images/logo/logo.inline.svg';
import HelpIcon from './icons/icon-help.inline.svg';
import PhoneIcon from './icons/icon-phone.inline.svg';
import MailIcon from './icons/icon-mail.inline.svg';
import IconPointer from './icons/icon-pointer.inline.svg';
import ArrowDownIcon from './icons/icon-arrow-down.inline.svg';
import ArrowUpIcon from './icons/icon-arrow-up.inline.svg';
import LeftIcon from './icons/icon-left.inline.svg';
import RightIcon from './icons/icon-right.inline.svg';
import TwitterIcon from './icons/icon-twitter.inline.svg';
import YoutubeIcon from './icons/icon-youtube.inline.svg';
import FacebookIcon from './icons/icon-facebook.inline.svg';
import QuoteIcon from './icons/icon-quote.inline.svg';
import Step1Icon from './icons/icon-step1.inline.svg';
import Step2Icon from './icons/icon-step2.inline.svg';
import Step3Icon from './icons/icon-step3.inline.svg';
import Step4Icon from './icons/icon-step4.inline.svg';
import PlusIcon from './icons/icon-plus.inline.svg';
import MinusIcon from './icons/icon-minus.inline.svg';
import CancelIcon from './icons/icon-cancel.inline.svg';
import ArrowLeft from './icons/icon-arrow-left.inline.svg';
import ArrowRight from './icons/icon-arrow-right.inline.svg';
import WhatsAppIcon from './icons/icon-whatsapp.inline.svg';
import CloseIcon from './icons/icon-close.inline.svg';

export {
  Logo,
  HelpIcon,
  IconPointer,
  PhoneIcon,
  MailIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  LeftIcon,
  RightIcon,
  TwitterIcon,
  YoutubeIcon,
  FacebookIcon,
  QuoteIcon,
  Step1Icon,
  Step2Icon,
  Step3Icon,
  Step4Icon,
  PlusIcon,
  MinusIcon,
  CancelIcon,
  ArrowLeft,
  ArrowRight,
  WhatsAppIcon,
  CloseIcon,
};
