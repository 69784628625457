export default {
  host: process.env.REACT_APP_OWNER_URL,
  api: process.env.REACT_APP_API_URL,
  nodeEnv: process.env.GATSBY_APP_ENV,
  qbApp: process.env.GATSBY_APP_QB_APP,
  get isDev() {
    return this.nodeEnv !== 'production';
  },
  minLoanAmount: 50,
  maxLoanAmount: 200,
  initialAmount: 150,
  minDays: 5,
  maxDays: Number(process.env.GATSBY_APP_MAX_DAYS ?? 30),
  interestRate: 0.0132,
};
