import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#d8dce6',
      main: '#0065b8',
      dark: '#003d6f',
      contrastText: '#000',
    },
  },
});
