import Cookies from 'js-cookie';
import config from '../config';

const QB_DOMAIN = config.nodeEnv === 'development' ? 'localhost' : '.quebueno.es';

export const setCookie = (key, value, options = {}) => {
  Cookies.set(key, value, { domain: QB_DOMAIN, ...options });
};

export const getCookie = (key) => Cookies.get(key);

export const removeCookie = (key) => {
  Cookies.remove(key, { domain: QB_DOMAIN });
};
