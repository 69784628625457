import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../Button';
import { Logo, IconPointer, PhoneIcon, HelpIcon } from '../../skin';
import CookiesDisclaimer from '../CookiesDisclaimer';
import './Header.scss';
import DiscountBanner from '../Discount/DiscountBanner';
import { getCookie } from '../../shared/libs/cookies';

const Header = ({ token, simple, userName, routes, handleDiscount }) => {
  const getInitial = () => userName.charAt(0);
  const getShortName = () => {
    const [firstName, lastName] = userName.split(' ');
    return `${firstName} ${lastName.charAt(0)}.`;
  };
  const handleClickLogin = () => {
    window.location.href = token ? `${routes.LOGIN}/${token}` : routes.LOGIN;
  };
  const handleClickUser = () => {
    window.location.href = routes.CBO;
  };

  return (
    <>
      {!userName && !getCookie('popup_promo_code') && <DiscountBanner onPress={handleDiscount} />}
      <CookiesDisclaimer />
      <header className={classnames('Header', { 'Header--logged': userName })}>
        <div className="Header-content">
          <a href={!token ? routes.HOME : `${routes.HOME}?t=${token}`}>
            <Logo className="Header-logo IconMainColor" />
          </a>
          <div className="Header-menu">
            {!userName && (
              <p className="Header-text">
                <span>Asistencia clientes: </span>
                <span>lun-jue 09:00 - 19:00, vie 09:00 - 17:00 </span>
              </p>
            )}
            {(!userName || simple) && (
              <div className="Header-contact">
                <a
                  className={classnames('Header-contactItem', {
                    'Header-contactItem--visible': simple,
                  })}
                  href="tel:932 71 44 88"
                >
                  <PhoneIcon className="Header-icon IconMainColor" aria-hidden="false" />
                  932 71 44 88
                </a>
                <a
                  className={classnames('Header-contactItem', {
                    'Header-contactItem--visible': simple,
                  })}
                  href="tel:900 83 11 63"
                >
                  <PhoneIcon className="Header-icon IconMainColor" aria-hidden="false" />
                  900 83 11 63
                </a>
              </div>
            )}
            {!simple && !userName && (
              <Button
                className="Header-button"
                name="login"
                submit
                size="small"
                onClick={handleClickLogin}
              >
                <span className="Header-buttonText">Iniciar Sesión</span>
              </Button>
            )}
            {!simple && (
              <>
                {userName && (
                  <a className="Link Header-help" href={routes.CBO_HELP}>
                    <HelpIcon className="Header-icon IconMainColor" aria-hidden="true" />
                    Ayuda
                  </a>
                )}
                {userName && (
                  <div className="Header-user" onClick={handleClickUser} role="link" tabIndex={0}>
                    <div className="Header-userInitial">{getInitial()}</div>
                    <div className="Header-userName">{getShortName()}</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {!userName && !simple && (
          <div className="Header-subHeader">
            <div className="Header-subHeader-content">
              <span>¿Ya eres cliente?</span>
              <span className="Header-login">
                Haz login y solicita hasta 900€
                <IconPointer className="Header-iconUp IconMainColor" aria-hidden="false" />
              </span>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  simple: PropTypes.bool,
  userName: PropTypes.string,
  routes: PropTypes.object.isRequired,
  handleDiscount: PropTypes.func,
};

export default Header;
