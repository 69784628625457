import React from 'react';
import PropTypes from 'prop-types';

const BUTTON_SIZE_SMALL = 'small';
const BUTTON_SIZE_MEDIUM = 'medium';
const BUTTON_SIZE_LARGE = 'large';

const propTypes = {
  primary: PropTypes.bool,
  link: PropTypes.bool,
  size: PropTypes.oneOf([BUTTON_SIZE_SMALL, BUTTON_SIZE_MEDIUM, BUTTON_SIZE_LARGE]),
  block: PropTypes.bool,
  'block-sm': PropTypes.bool,
  loading: PropTypes.bool,
  warning: PropTypes.bool,
  cardPending: PropTypes.bool,
  submit: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  uppercase: PropTypes.bool,
};

const defaultProps = {
  size: BUTTON_SIZE_MEDIUM,
  className: '',
  uppercase: true,
};

export default function Button({
  children,
  className,
  primary,
  link,
  size,
  loading,
  href,
  block,
  submit,
  'block-sm': blockSm,
  warning,
  cardPending,
  uppercase,
  ...rest
}) {
  const classNames = [className, 'Button'];
  if (primary) {
    classNames.push('Button--primary');
  }
  if (link) {
    classNames.push('Button--link');
  }
  if (loading) {
    classNames.push('is-spinning');
  }
  if (warning) {
    classNames.push('Button--iconWarning');
  }
  if (cardPending) {
    classNames.push('Button--iconCardPending');
  }
  if (block) {
    classNames.push('Button--block');
  }
  if (blockSm) {
    classNames.push('Button--blockSmOnly');
  }
  if (uppercase) {
    classNames.push('uppercase');
  }

  let sizeClassName = 'Button--medium';
  if (size === BUTTON_SIZE_SMALL) {
    sizeClassName = 'Button--small';
  }
  if (size === BUTTON_SIZE_LARGE) {
    sizeClassName = 'Button--large';
  }
  classNames.push(sizeClassName);

  if (href) {
    return (
      <a href={href} className={classNames.join(' ')} {...rest}>
        {children}
      </a>
    );
  }
  const buttonType = submit ? 'submit' : 'button';

  return (
    <button type={buttonType} className={classNames.join(' ')} {...rest}>
      {children}
    </button>
  );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
