import config from './config';

// GATSBY ROUTES
export const HOME = '/';
export const WHO_WE_ARE = '/quienes-somos';
export const PRIVACY_POLICY = '/politica-de-privacidad';
export const COOKIES_POLICY = '/politica-de-cookies';
export const GENERAL_TERMS = '/condiciones-generales';
export const LEGAL_DISCLAIMER = '/aviso-legal';
export const BLOG = '/blog';
export const ETHIC = '/etica-y-cumplimiento';
export const INFORMATION_INTERNAL_SYSTEM = '/sistema-interno-de-informacion';

// QB APP ROUTES
export const LOGIN = `${config.qbApp}/login`;
export const CBO = `${config.qbApp}/mi-cuenta`;
export const CBO_HELP = `${config.qbApp}/mi-cuenta/ayuda`;
export const FAQ = `${config.qbApp}/preguntas-frecuentes`;
export const LOAN_APPLICATION = `${config.qbApp}/prestamo`;
export const CBO_LOAN_APPLICATION = `${config.qbApp}/mi-cuenta/solicitud-prestamo`;
