import React from 'react';
import PropTypes from 'prop-types';
import { Logo, PhoneIcon, MailIcon, TwitterIcon, YoutubeIcon, FacebookIcon } from '../../skin';
import './Footer.scss';

const Footer = ({ simple, routes, Link }) => (
  <footer className="Footer">
    {!simple && (
      <div className="Footer-content">
        <div className="Grid">
          <div className="Footer-logoWrapper Grid-cell u-md-size1of4">
            <Logo className="Footer-logo IconMainColor" aria-hidden="false" />
          </div>
          <div className="Grid-cell u-md-size1of4 Footer-list">
            <p className="Footer-title">Sobre nosotros</p>
            <ul>
              <li className="Footer-element">
                <Link className="Footer-link" title="Quiénes somos" to={routes.WHO_WE_ARE}>
                  Quiénes somos
                </Link>
              </li>
              <li className="Footer-element">
                <Link
                  className="Footer-link"
                  title="Política de privacidad"
                  to={routes.PRIVACY_POLICY}
                >
                  Política de privacidad
                </Link>
              </li>
              <li className="Footer-element">
                <Link
                  className="Footer-link"
                  title="Política de cookies"
                  to={routes.COOKIES_POLICY}
                >
                  Política de cookies
                </Link>
              </li>
              <li className="Footer-element">
                <Link
                  className="Footer-link"
                  title="Condiciones generales"
                  to={routes.GENERAL_TERMS}
                >
                  Condiciones generales
                </Link>
              </li>
              <li className="Footer-element">
                <Link className="Footer-link" title="Aviso legal" to={routes.LEGAL_DISCLAIMER}>
                  Aviso legal
                </Link>
              </li>
              <li className="Footer-element">
                <Link className="Footer-link" title="Ética y cumplimiento" to={routes.ETHIC}>
                  Ética y cumplimiento
                </Link>
              </li>
              <li className="Footer-element">
                <Link
                  className="Footer-link"
                  title="Sistema interno de información"
                  to={routes.INFORMATION_INTERNAL_SYSTEM}
                >
                  Sistema interno de información
                </Link>
              </li>
            </ul>
          </div>
          <div className="Grid-cell u-md-size1of4 Footer-list">
            <p className="Footer-title">¡Qué Bueno!</p>
            <ul>
              <li className="Footer-element">
                <Link className="Footer-link" title="Inicio" to={routes.HOME}>
                  Inicio
                </Link>
              </li>
              <li className="Footer-element">
                <a className="Footer-link" title="Blog" href={routes.BLOG}>
                  Blog
                </a>
              </li>
              <li className="Footer-element">
                <a className="Footer-link" title="Preguntas frecuentes" href={routes.FAQ}>
                  Preguntas frecuentes
                </a>
              </li>
            </ul>
          </div>
          <div className="Grid-cell u-md-size1of4 Footer-list">
            <p className="Footer-title">Asistencia clientes:</p>
            <p className="Footer-text">lun-jue 09:00 - 19:00, vie 09:00 - 17:00</p>
            <a className="Footer-phone" href="tel:932 71 44 88">
              <PhoneIcon className="Footer-icon IconMainColor" aria-hidden="false" />
              932 71 44 88
            </a>
            <a className="Footer-phone" href="tel:900 83 11 63">
              <PhoneIcon className="Footer-icon IconMainColor" aria-hidden="false" />
              900 83 11 63
            </a>
            <a className="Footer-mail" href="mailto:info@quebueno.es">
              <MailIcon className="Footer-icon IconMainColor" aria-hidden="false" />
              info@quebueno.es
            </a>
            <div className="Footer-social">
              <a href="https://twitter.com/quebuenocredito" title="Twitter">
                <TwitterIcon className="Footer-socialIcon IconMainColor" aria-hidden="false" />
              </a>
              <a href="https://www.youtube.com/channel/UCPK-ZMJK5ekKf1dhprJI0kw" title="Twitter">
                <YoutubeIcon className="Footer-socialIcon IconMainColor" aria-hidden="false" />
              </a>
              <a href="https://www.facebook.com/quebuenocreditos" title="Twitter">
                <FacebookIcon className="Footer-socialIcon IconMainColor" aria-hidden="false" />
              </a>
            </div>
          </div>
        </div>
      </div>
    )}
    <div className="Footer-subFooter">
      <p className="Footer-company">
        NBQ Technology, S.A.U. CIF A65559296 · Calle Jordi Girona 29, 08034 Barcelona, España.
      </p>
    </div>
  </footer>
);

Footer.propTypes = {
  simple: PropTypes.bool,
  routes: PropTypes.object,
  Link: PropTypes.object,
};

export default Footer;
