import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../skin/scss/theme';

import './CookiesConfiguration.scss';
import { useState } from 'react';

const CookiesConfiguration = ({ visible, handleClose, initialValues }) => {
  const [cookies, setCookies] = useState(initialValues);

  const handleChange = (event) => {
    const cookies_v = [...cookies];
    cookies_v.find((it) => it.eventName === event.target.name).checked = event.target.checked;
    setCookies(cookies_v);
  };

  const handleAccept = () => {
    handleClose(cookies);
  };

  const modifyOptions = (checked) => {
    const cookies_v = [...cookies];
    cookies_v.forEach((it) => (it.checked = checked));
    setCookies(cookies_v);
  };

  return (
    <Dialog open={visible} keepMounted>
      <DialogTitle>QueBueno! Política de cookies</DialogTitle>
      <DialogContent>
        <div className="CookiesConfiguration-text">
          Una cookie es un fichero que se descarga en el ordenador o dispositivo móvil del usuario
          al acceder a determinados sitios web. Las cookies permiten, entre otras cosas, almacenar y
          recuperar información sobre el número de visitas, los hábitos de navegación de un usuario
          o de su equipo o dispositivo móvil y, dependiendo de la información que contengan y de la
          forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.
        </div>
        <br />
        <div>
          A continuación puedes configurar el uso de las cookies. También puedes{' '}
          <a className="Link" href="#" onClick={() => modifyOptions(false)}>
            rechazar todas
          </a>{' '}
          o{' '}
          <a className="Link" href="#" onClick={() => modifyOptions(true)}>
            aceptar todas
          </a>
        </div>
        <br />
        <div className="CookiesConfiguration-cookies-container">
          {cookies &&
            cookies.map((cookie) => (
              <div key={cookie.name} className="CookiesConfiguration-cookie">
                <p>{cookie.name}</p>
                <ThemeProvider theme={theme}>
                  <Switch
                    color="primary"
                    name={cookie.eventName}
                    onChange={handleChange}
                    checked={cookie.checked}
                  />
                </ThemeProvider>
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <a href="#" className="Button Button--primary" aria-label="Accept" onClick={handleAccept}>
          <span>Guardar</span>
        </a>
      </DialogActions>
    </Dialog>
  );
};

CookiesConfiguration.defaultProps = {
  visible: false,
};

CookiesConfiguration.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  initialValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      eventName: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
};

export default CookiesConfiguration;
