import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import * as routes from '../../shared/routes';
import * as gtm from '../../shared/libs/gtm';
import { getCookie, setCookie } from '../../shared/libs/cookies';
import CookiesConfiguration from '../CookiesConfiguration';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './CookiesDisclaimer.scss';

const CookiesDisclaimer = () => {
  const [visible, setVisible] = useState(false);
  const [configVisible, setConfigVisible] = useState(false);

  const initialValues = [
    { name: 'Google Analytics', eventName: 'analytics_consent', checked: true },
    { name: 'Google Adwords', eventName: 'adwords_consent', checked: true },
    { name: 'Facebook', eventName: 'facebook_consent', checked: true },
    { name: 'Hotjar', eventName: 'hotjar_consent', checked: true },
  ];

  useEffect(() => {
    setVisible(!getCookie('gatsby-gdpr-google-tagmanager'));
  }, []);

  const handleConfiguration = () => {
    setVisible(false);
    setConfigVisible(true);
  };

  const handleClose = (cookies = initialValues) => {
    setVisible(false);
    setConfigVisible(false);
    setCookie('gatsby-gdpr-google-tagmanager', true);

    cookies.filter((cookie) => cookie.checked).forEach((cookie) => gtm.pushEvent(cookie.eventName));
  };

  return (
    <>
      <CookiesConfiguration
        visible={configVisible}
        initialValues={initialValues}
        handleClose={handleClose}
      />
      <Dialog open={visible} keepMounted>
        <DialogTitle>QueBueno! Política de cookies</DialogTitle>
        <DialogContent>
          <div className="CookiesDisclaimer-text">
            Este sitio web utiliza cookies propias y de terceros para conocer tus hábitos de
            navegación con fines analíticos. Si estás de acuerdo, haz clic en el botón “Aceptar”
            para prestar tu consentimiento. En caso contrario, puedes cambiar la configuración de
            cookies clicando aquí. Para obtener más información consulte la{' '}
            <Link className="Link" to={routes.COOKIES_POLICY}>
              política de cookies
            </Link>
            .
          </div>
        </DialogContent>
        <DialogActions>
          <a
            href="#"
            className="Button Button--link"
            aria-label="Config"
            name="test_cookies_config"
            onClick={handleConfiguration}
          >
            <span>Configurar</span>
          </a>
          <a
            href="#"
            className="Button Button--primary"
            aria-label="Accept"
            name="test_cookies_accept"
            onClick={() => handleClose()}
          >
            <span>Aceptar</span>
          </a>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CookiesDisclaimer;
