import PropTypes from 'prop-types';
import React from 'react';

const DiscountBanner = ({ onPress, text }) => {
  return (
    <button className="Discount-banner" onClick={onPress}>
      <p className="Discount-banner-text">{text}</p>
    </button>
  );
};

DiscountBanner.defaultProps = {
  text: 'CONSIGUE AQUÍ UN 5% DE DESCUENTO INMEDIATO EN TU PRÉSTAMO DE HOY',
};

DiscountBanner.propTypes = {
  text: PropTypes.string,
  onPress: PropTypes.func,
};

export default DiscountBanner;
